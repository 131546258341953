const sizes = {
    xs: {min: 0, max: 599},
    sm: {min: 600, max: 899},
    md: {min: 900, max: 1019},
    lg: {min: 1020, max: 1279},
    xl: {min: 1280, max: Infinity},
}

const useScreenSizes = {
    between(lowerBound, upperBound) {
        return `(min-width: ${sizes[lowerBound].min}px) and (max-width: ${sizes[upperBound].max}px)`;
    },
    greaterThan(key) {
        return `(min-width: ${sizes[key].min}px)`;
    },
    lessThan(key) {
        return `(max-width: ${sizes[key].min - 1}px)`;
    }
}

export default useScreenSizes
