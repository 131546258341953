import React, { useState } from "react";
import ReactGA from "react-ga";
import { jobs } from "../jobs/data";
import { Container, FormField } from "components";
import { Link, useParams } from "react-router-dom";
import Row from "../../components/row";
import Column from "../../components/column";
import { useForm } from "react-hook-form";
import axios from "axios";
import { theme, colors } from "theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faMapMarkerAlt,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";

const fileType = {
  PDF: "application/pdf",
  DOC: "application/msword",
  DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};

export default function Job() {
  ReactGA.pageview(window.location.pathname);
  const { id } = useParams();
  const [filename, setFilename] = useState("");
  const [feedback, setFeedback] = useState({ error: false, text: "" });
  const { register, handleSubmit, errors } = useForm();
  const job = jobs.get(id);

  const submit = (values) => {
    const formData = new FormData();
    const { file, ...otherValues } = values;

    formData.append("file", file[0], file[0].name);
    formData.append("jobTitle", job.title);
    formData.append("jobLink", window.location.href);

    for (const value in otherValues) {
      formData.append(value, otherValues[value]);
    }

    axios
      .post(
        "https://a25ihdpt07.execute-api.us-east-1.amazonaws.com/prod/mailer/send-with-attachment",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Api-Key": "zlQvaaUgjK5htLMjxIKQ249r7hesK5cH5yESDrIg",
          },
        }
      )
      .then(() =>
        setFeedback({
          error: false,
          text: "Success! Your application was received.",
        })
      )
      .catch(() =>
        setFeedback({
          error: true,
          text: "An error occurred. Please try again later.",
        })
      );
  };

  return (
    <Container style={{ paddingTop: "3em", paddingBottom: "4em" }}>
      <Link to="/jobs">Back to Jobs</Link>

      <div style={{ marginBottom: "1em" }}>
        <h3 style={{ marginBottom: 0, marginTop: ".5em" }}>{job.title}</h3>
        <div
          style={{
            display: "flex",
            flexFlow: "wrap",
            marginLeft: "-10px",
            marginRight: "-10px",
          }}
        >
          <div style={{ padding: "0 10px" }}>
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              style={{ color: colors.primary, marginRight: "3px" }}
            />{" "}
            {job.location}
          </div>
          <div style={{ padding: "0 10px" }}>
            <FontAwesomeIcon
              icon={faDollarSign}
              style={{ color: colors.primary, marginRight: "3px" }}
            />{" "}
            {job.pay}
          </div>
        </div>
      </div>
      <Row>
        <Column
          width="50%"
          style={{ marginRight: "3em", paddingBottom: "2em" }}
        >
          <div>{job.description}</div>

          {job.benefits.length > 0 && (
            <div style={{ padding: "1em 0" }}>
              <h5>{job.title} benefits:</h5>
              {job.benefits.map((benefit) => (
                <li>{benefit}</li>
              ))}
            </div>
          )}

          {job.responsibilties.length > 0 && (
            <div style={{ padding: "1em 0" }}>
              <h5>{job.title} responsibilties include:</h5>
              {job.responsibilties.map((responsibility) => (
                <li>{responsibility}</li>
              ))}
            </div>
          )}

          {job.qualifications.length > 0 && (
            <div style={{ padding: "1em 0" }}>
              <h5>Qualifications for {job.title}s:</h5>
              {job.qualifications.map((qualification) => (
                <li>{qualification}</li>
              ))}
            </div>
          )}

          <p>
            We maintain a drug-free workplace and perform pre-employment
            substance abuse testing.
          </p>
        </Column>
        <Column width="40%">
          <h4>Apply now</h4>

          <form onSubmit={handleSubmit(submit)}>
            <label
              className={theme.buttonLink}
              style={{ display: "inline-block" }}
              htmlFor="file-upload"
            >
              <FontAwesomeIcon icon={faUpload} style={{ marginRight: "5px" }} />{" "}
              Choose resume file
            </label>
            {filename && <p>{filename}</p>}
            <input
              accept={Object.values(fileType).join(",")}
              type="file"
              name="file"
              id="file-upload"
              style={{ display: "none" }}
              onChange={({ target }) => {
                if (target.files[0]) {
                  setFilename(target.files[0].name);
                }
              }}
              ref={register({
                required: "Resume file is required",
                validate: (fileList) => {
                  const file = fileList[0];

                  const isFileType =
                    file.type === fileType.PDF ||
                    file.type === fileType.DOC ||
                    file.type === fileType.DOCX;

                  if (!isFileType) {
                    return "The file type must be .pdf, .doc, or .docx";
                  }

                  if (file.size > 5000000) {
                    return "The file size must be less than 5MB";
                  }

                  return true;
                },
              })}
            />

            {errors.file && (
              <p style={{ color: "red", fontSize: "12px", marginTop: ".5em" }}>
                {errors.file.message}
              </p>
            )}

            <FormField
              name="firstName"
              error={errors.firstName}
              register={register({
                required: "First Name is required",
              })}
            >
              First Name
            </FormField>

            <FormField
              name="lastName"
              error={errors.lastName}
              register={register({
                required: "Last Name is required",
              })}
            >
              Last Name
            </FormField>

            <FormField
              name="email"
              error={errors.email}
              register={register({
                required: "Email address is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Email address is invalid",
                },
              })}
            >
              Email
            </FormField>

            <FormField
              name="phoneNumber"
              error={errors.phoneNumber}
              register={register({
                required: "Phone Number is required",
              })}
            >
              Phone Number
            </FormField>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1em",
              }}
            >
              <button type="submit">Submit</button>
            </div>
            {feedback.text && (
              <p
                style={{
                  textAlign: "center",
                  marginTop: "1em",
                  color: feedback.error ? "red" : colors.primary,
                }}
              >
                {feedback.text}
              </p>
            )}
          </form>
        </Column>
      </Row>
    </Container>
  );
}
