import React, { Fragment, useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import ReactGA from 'react-ga';

import { Container, Row, Column } from 'components'
import { colors } from 'theme'
import sass from './locations.sass'
import { data } from './data'

const endpoint = 'https://acc9g63mcb.execute-api.us-east-1.amazonaws.com/dev/distance'
const headers = { 
  'Content-Type': 'application/json',
  'X-Api-Key': 'fwXLHb7Ru19676HJIU6Hx5cj6Zwmce9Wa8HFAlQ0'
}

export default function Locations() {
  ReactGA.pageview(window.location.pathname);

  let { query } = useLocation()
  let [address, setAddress] = useState(query && query.address ? query.address : '')
  let [coordinates, setCoordinates] = useState(query && query.coordinates ? query.coordinates : null)
  let [errorMessage, setErrorMessage] = useState('')
  let [loading, setLoading] = useState(false)
  const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const [markers, setMarkers] = useState(new Map())
  const [locations, setLocations] = useState(data)

  useEffect(() => {
    if (markers.size > 0) {
      locations.sort((a, b) => {
          return parseFloat(a.distance) - parseFloat(b.distance)
      }).map((store, i) => {
          markers.get(store.key).setLabel(getLabel(i))
      })
    }
  }, [locations])

  useEffect(() => {
    if (coordinates)
      getDistancesByCoordinates()
    else if (address)
      getDistancesByAddress()

    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: 26.375, lng: -81.777 },
      zoom: 10
    });

    locations.map((store, i) => {
      markers.set(store.key, new window.google.maps.Marker({
        position: { lat: store.latitude, lng: store.longitude },
        map: map,
        title: store.key,
        label: getLabel(i)
      }));
    })
  }, []);

  function getLabel(index) {
    return labels[index % labels.length]
  }

  const getDistancesByAddress = () => {
    setLoading(true)
    axios.post(
      `${endpoint}/address`,
      { address, locations },
      { headers }
    )
    .then(resp => {
      setLocations(resp.data.locations)
    })
    .catch(() => setErrorMessage('Unable to find address'))
    .finally(() => setLoading(false))
  }

  const getDistancesByCoordinates = () => {
    setLoading(true)
    axios.post(
      `${endpoint}/coordinates`,
      { coordinates, locations },
      { headers }
    )
    .then(resp => {
      setLocations(resp.data.locations)
    })
    .catch(() => setErrorMessage('Unable to find address'))
    .finally(() => setLoading(false))
  }

  const onChange = ({ target }) => {
    setErrorMessage('')
    setAddress(target.value)
  }

  const onKeyDown = ({ keyCode }) => {
    if (keyCode === 13) {
      getDistancesByAddress()
    }
  }

  const ErrorMessage = () => {
    if (errorMessage) return <span style={{ color: 'red' }}>{errorMessage}</span>
    else return null
  }

  const LoadLocations = () => {
    if (loading) return (
      <div style={{ marginTop: '2em', textAlign: 'center' }}>
        <FontAwesomeIcon style={{ color: colors.primary, fontSize: '3em' }} icon="spinner" spin />
      </div>
    )
    else return (
      locations.map((store, i) =>
        <div key={store.key} style={{ padding: '1em 0' }}>
          <h6>{getLabel(i)}. {store.key}</h6>
          <p>{store.address}</p>
          <p>{store.phone}</p>
          <p>Mon - Fri: {store.hours.weekday}</p>
          <p>Sat: {store.hours.saturday}</p>
          <p>Sun: {store.hours.sunday}</p>
          {store.distance > 0 ? <div>{store.distance.toFixed(2)} miles away</div> : null}
        </div>
      )
    )
  }

  return (
    <Fragment>
      <Container style={{ paddingBottom: '4em' }}>
        <div>
          <div style={{ paddingBottom: '3em', paddingTop: '3em' }}>
            <h3 style={{ color: colors.primary }}>Our Locations</h3>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <h4 style={{ color: colors.primary }}>When You Need The Personal Touch...</h4>
                <p>Visit one of our stores, conveniently located in 9 locations.</p>
              </div>
            </div>
          </div>
        </div>
        <Row reverse>
          <Column width="35%">
            <div style={{ display: 'flex' }}>
              <input value={address} type="text"
                onChange={onChange}
                placeholder="Enter an address"
                onKeyDown={onKeyDown}
                style={{
                  background: colors.kindaLight,
                  border: 'none',
                  borderRadius: '3px',
                  color: colors.dark,
                  fontSize: '14px',
                  padding: '.75em 1em',
                  width: '100%'
                }}
                />
              <button onClick={getDistancesByAddress} style={{
                marginLeft: '.5em',
                padding: '.75em 2em'
              }}>
                <FontAwesomeIcon style={{ fontSize: '1.25em' }} icon="search" />
              </button>
            </div>
            <ErrorMessage />
            <LoadLocations />
          </Column>
          <Column width="50%">
            <div id="map" className={sass.map} />
          </Column>
        </Row>
      </Container>
    </Fragment>
  )
}

