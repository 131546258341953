import React from 'react'

import { colors, weights } from 'theme'

export default function FormField(props) {
  let { 
    children,
    contactattributeid,
    error,
    id,
    light,
    maxLength,
    name,
    register
  } = props

  return (
    <div style={{ padding: '.5em 0', display: 'flex', flexDirection: 'column' }}>
      <label style={{ color: light ? colors.kindaLight : colors.primary, fontWeight: weights.kindaBold }}>
        {children}
      </label>
      <input type="text" name={name} ref={register} id={id}
        maxLength={maxLength} contactattributeid= {contactattributeid}
        style={{
          background: colors.kindaLight,
          border: 'none',
          outline: error && 0,
          boxShadow: error && '0 0 1px 1px red',
          borderRadius: '3px',
          padding: '1.25em 1em'
        }} />
      {error && <p style={{ color: 'red', fontSize: '12px', marginTop: '.5em' }}>{error && error.message}</p>}
    </div>
  )
}