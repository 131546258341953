import React from 'react'

import { Container } from 'components'

export default function Policy({ policyKey }) {
  return (
    <Container>
      <div
        style={{ paddingTop: "3em", paddingBottom: "3em"}}
        data-policy-key={policyKey}
        id="policy"
        width="640"
        height="480"
        data-extra="h-align=left&h-depth=3&table-style=accordion"
      >
      </div>
    </Container>
  )
}

