import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faTimes,
  faBars,
  faSearch,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga";

import {
  AboutUs,
  Contact,
  Home,
  Services,
  Locations,
  HowItWorks,
  CreditCard,
  Policy,
  Jobs,
  Job,
  Comca,
  GooglePlayPolicy
} from "templates";
import { sm } from "theme";
import { Header, Footer } from "components";

library.add(faMapMarkerAlt, faPhoneAlt, faTimes, faBars, faSearch, faSpinner);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function App() {
  ReactGA.initialize("UA-164487026-1");

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <section style={{ flex: "1 0 auto" }} className={sm.headerMargin}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/services" exact component={Services} />
          <Route path="/locations" exact component={Locations} />
          <Route path="/how-it-works" exact component={HowItWorks} />
          <Route path="/about-us" exact component={AboutUs} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/credit-card" exact>
            <Comca
              src="https://app.comcamobile.com:8100/webpay/webpaysite.html?storeid=1100"
              height="850px"
            />
          </Route>
          <Route path="/sign-up" exact>
            <Comca
              src="https://app.comcamobile.com:8100/webpay/webpaysite.html?storeid=1100&signup=1"
              height="1450px"
            />
          </Route>
          <Route path="/privacy-policy" exact>
            <Policy policyKey="Vm1aaVJITkZNa2t6WkRrd05tYzlQUT09" />
          </Route>
          <Route path="/terms-of-service" exact>
            <Policy policyKey="TkVONlF5OXFiMDF2YURCT1pHYzlQUT09" />
          </Route>
          <Route path="/google-play-policy" exact>
            <GooglePlayPolicy />
          </Route>
          <Route path="/jobs" exact component={Jobs} />
          <Route path="/job/:id" exact component={Job} />
          <Route path="/" component={() => <div>404</div>} />
        </Switch>
      </section>
      <Footer />
    </BrowserRouter>
  );
}
