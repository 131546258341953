import React from 'react'

import sass from './hero.sass'

export default function Hero({ children, image, minHeight }) {
  return (
    <section className={sass.hero} 
        style={{ backgroundImage: `url(${image})`, minHeight }}>
      {children}
    </section>
  )
}