import React, { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga";

import {
  Column,
  Container,
  FormField,
  FormTextField,
  Hero,
  Row,
} from "components";
import { colors, theme, weights } from "theme";
import { heroContact } from "images";
import { submitForm } from "helpers";

const params = {
  to: ["info@myfashionfresh.com"],
  source: "contact",
};

export default function Contact() {
  ReactGA.pageview(window.location.pathname);

  const { register, handleSubmit, errors } = useForm();
  const [feedback, setFeedback] = useState({ error: false, text: null });

  function submit(values) {
    submitForm({ ...values, ...params })
      .then(() => setFeedback({ error: false, text: "Success!" }))
      .catch(() =>
        setFeedback({
          error: true,
          text: "An error occurred. Please try again later.",
        })
      );
  }

  return (
    <Fragment>
      <Hero minHeight={"200px"} image={heroContact} />
      <div style={{ background: colors.primary }}>
        <Container style={{ paddingBottom: "4em" }}>
          <div style={{ paddingTop: "3em" }}>
            <h3 style={{ color: "#fff" }}>Contact Us</h3>
          </div>
          <Row>
            <Column width="30%">
              <p style={{ color: colors.light }}>
                We take our commit to our users seriously. If you need
                assistance, have questions about our service or are experiencing
                technical difficulties, please do not hesitate to contact us.
              </p>
              <div style={{ fontWeight: weights.regular, marginTop: "2em" }}>
                <div style={{ display: "flex", marginTop: ".75em" }}>
                  <FontAwesomeIcon
                    style={{
                      color: colors.kindaLight,
                      marginTop: ".25em",
                      marginRight: ".75em",
                    }}
                    icon="map-marker-alt"
                  />
                  <span style={{ color: "#fff" }}>
                    <p>1926 Trade Center Way</p>
                    <p>Naples, FL 34109</p>
                  </span>
                </div>
                <div style={{ display: "flex", marginTop: ".75em" }}>
                  <FontAwesomeIcon
                    style={{
                      color: colors.kindaLight,
                      marginTop: ".25em",
                      marginRight: ".75em",
                    }}
                    icon="phone-alt"
                  />
                  <p style={{ color: "#fff" }}>239.596.3100</p>
                </div>
                <div style={{ display: "flex", marginTop: ".75em" }}>
                  <FontAwesomeIcon
                    style={{
                      color: colors.kindaLight,
                      marginTop: ".25em",
                      marginRight: ".75em",
                    }}
                    icon={faEnvelope}
                  />
                  <p style={{ color: "#fff" }}>info@myfashionfresh.com</p>
                </div>
              </div>
            </Column>
            <Column width="30%">
              <form onSubmit={handleSubmit(submit)}>
                <Row>
                  <Column width="40%">
                    <FormField
                      light
                      name="firstName"
                      error={errors.firstName}
                      register={register({
                        required: "First name is required",
                      })}
                    >
                      First Name
                    </FormField>
                  </Column>
                  <Column width="40%">
                    <FormField
                      light
                      name="lastName"
                      error={errors.lastName}
                      register={register({
                        required: "Last name is required",
                      })}
                    >
                      Last Name
                    </FormField>
                  </Column>
                </Row>
                <FormField
                  light
                  name="email"
                  error={errors.email}
                  register={register({
                    required: "Email address is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Email address is invalid",
                    },
                  })}
                >
                  Email
                </FormField>
                <FormTextField
                  light
                  name="message"
                  error={errors.message}
                  register={register({
                    required: "Message is required",
                  })}
                >
                  Message
                </FormTextField>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1em",
                  }}
                >
                  <button className={theme.invert} type="submit">
                    Send
                  </button>
                </div>
              </form>
              <p
                style={{
                  textAlign: "right",
                  marginTop: "1em",
                  color: colors.light,
                }}
              >
                {feedback.text ? feedback.text : null}
              </p>
            </Column>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}
