import React from 'react'

import { colors, weights } from 'theme'

export default function FormTextField(props) {
  let { 
    children,
    error,
    light,
    name,
    register
  } = props

  return (
    <div style={{ padding: '0.5em 0', display: 'flex', flexDirection: 'column' }}>
      <label style={{ color: light ? colors.kindaLight : colors.primary, fontWeight: weights.kindaBold }}>
        {children}
      </label>
      <textarea name={name} ref={register} style={{
        background: colors.kindaLight,
        border: 'none',
        borderRadius: '3px',
        boxShadow: error && '0 0 1px 1px red',
        fontSize: '14px',
        height: '100px',
        outline: error && 0,
        padding: '1.5em 1em'
      }} />
      {error && <p style={{ color: 'red', fontSize: '12px', marginTop: '.5em' }}>{error && error.message}</p>}
    </div>
  )
}