import React from 'react'

import sass from './banner.sass'
import Container from '../container'

export default function Banner() {

  return (
    <div className={sass.banner}>
      <Container className={sass.bannerContainer}>
        <div className={sass.message}>
          <h4>Lighten the load this Mother's Day!</h4>
          <p>Purchase a $100 gift card and receive an additional $20 for <b>FREE</b></p>
        </div>
        <div>
          <a href="https://myfashionfresh.cardfoundry.com/giftcards.php" target="_blank" className={sass.button}>See Details</a>
        </div>
      </Container>
    </div>
  )
}
