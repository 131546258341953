import sm from './small.sass'
import theme from './theme.sass'

const colors = {
    primary: '#5B42AA',
    secondary: '#F3B337',
    dark: '#4B368E',
    kindaLight: '#F1EDFA',
    light: '#FAF8FF'
}

const weights = {
    reallyLight: 200,
    light: 300,
    regular: 400,
    kindaBold: 500,
    bold: 600
}

module.exports = {
    colors,
    sm,
    theme,
    weights
}