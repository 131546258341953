import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { colors } from "theme";
import FormField from "../form-field";
import Column from "../column";
import Row from "../row";
import { submitForm } from "helpers";
import sass from "./signUpForm.sass";

const forms = new Map([
  [
    "signUp",
    {
      title: "Sign Up",
      to: ["roberto@myfashionfresh.com", "info@myfashionfresh.com"],
    },
  ],
  [
    "pickUp",
    {
      title: "Request Pickup",
      to: ["laverne@myfashionfresh.com", "info@myfashionfresh.com"],
    },
  ],
]);

export default function SignUpForm({ source }) {
  const { register, handleSubmit, errors } = useForm();
  const [feedback, setFeedback] = useState({ error: false, text: null });

  const params = {
    to: forms.get(source).to,
    source,
  };

  function submit(values) {
    submitForm({ ...values, ...params })
      .then(() => setFeedback({ error: false, text: "Success!" }))
      .catch(() =>
        setFeedback({
          error: true,
          text: "An error occurred. Please try again later.",
        })
      );
  }

  return (
    <div className={sass.signUpForm} style={{ backgroundColor: colors.light }}>
      <div
        style={{
          margin: "0.5em auto",
          maxWidth: "500px",
        }}
      >
        <h3
          style={{
            color: colors.primary,
            marginBottom: "1em",
            textAlign: "center",
          }}
        >
          Are you ready to get fresh?
        </h3>
        <form onSubmit={handleSubmit(submit)}>
          <Row>
            <Column width="40%">
              <FormField
                name="firstName"
                error={errors.firstName}
                register={register({
                  required: "First name is required",
                })}
              >
                First Name
              </FormField>
            </Column>
            <Column width="40%">
              <FormField
                name="lastName"
                register={register({
                  required: "Last name is required",
                })}
                error={errors.lastName}
              >
                Last Name
              </FormField>
            </Column>
          </Row>
          <FormField
            name="email"
            error={errors.email}
            register={register({
              required: "Email address is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Email address is invalid",
              },
            })}
          >
            Email
          </FormField>
          <FormField
            name="address"
            register={register({
              required: "Address is required",
            })}
            error={errors.address}
          >
            Address
          </FormField>
          <FormField
            name="phoneNumber"
            register={register({
              required: "Phone number is required",
            })}
            error={errors.phoneNumber}
          >
            Phone Number
          </FormField>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1em",
            }}
          >
            <button type="submit">{forms.get(source).title}</button>
          </div>
          <p
            style={{
              textAlign: "center",
              marginTop: "1em",
              color: feedback.error ? "red" : colors.primary,
            }}
          >
            {feedback.text}
          </p>
        </form>
      </div>
    </div>
  );
}
