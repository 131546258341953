import React, { useState, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from "react-ga";

import {
  Column,
  Container,
  Faq,
  Heading,
  Hero,
  Modal,
  Row,
  SignUpForm,
  SubscribeForm,
} from "components";
import {
  app,
  appStore,
  hangerMan,
  heroHome,
  googlePlay,
  map,
  storefront,
  truck,
  quoteOne,
  quoteTwo,
  quoteThree,
} from "images";
import { colors, theme, weights } from "theme";
import sass from "./home.sass";
import { Link } from "react-router-dom";

function Tagline() {
  return (
    <section
      className={sass.tagline}
      style={{
        background: colors.primary,
      }}
    >
      <h3
        style={{
          color: "#FAF8FF",
          fontWeight: weights.bold,
        }}
      >
        Clean Clothes. Happy People. &trade;
      </h3>
      <h6
        style={{
          color: "#FAF8FF",
          fontWeight: weights.reallyLight,
          fontSize: "1.1em",
        }}
      >
        Trusted Local Service, Keeping Southwest Florida Fresh Since 1987.
      </h6>
    </section>
  );
}

function App() {
  return (
    <Container className={sass.app}>
      <Heading title="Download Our App" />
      <Row reverse style={{ textAlign: "center" }}>
        <Column width="40%">
          <p className={sass.onTheGo}>
            <span
              style={{ color: colors.primary, fontWeight: weights.regular }}
            >
              Your Clothes
            </span>
            <br />
            <span style={{ color: colors.secondary, fontWeight: weights.bold }}>
              On the Go.
            </span>
          </p>
          <p
            style={{
              fontSize: "20px",
              fontWeight: weights.reallyLight,
              margin: "2em auto",
              maxWidth: "350px",
            }}
          >
            Download our app for rewards and other convenient free delivery
            service options.
          </p>
          <a
            href="https://apps.apple.com/us/app/fashion-fresh/id1276893609"
            target="_blank"
          >
            <img style={{ maxWidth: "180px", margin: ".5em" }} src={appStore} />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.fashion.fresh"
            target="_blank"
          >
            <img
              style={{ maxWidth: "180px", margin: ".5em" }}
              src={googlePlay}
            />
          </a>
        </Column>
        <Column width="40%">
          <img className={sass.phone} src={app} />
        </Column>
      </Row>
    </Container>
  );
}

function Location() {
  const [address, setAddress] = useState("");
  const [query, setQuery] = useState({});
  const [redirectLocations, setRedirectLocations] = useState(false);
  const [loading, setLoading] = useState(false);

  function useLocation() {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      setQuery({ coordinates: [coords.longitude, coords.latitude] });
      setRedirectLocations(true);
      setLoading(false);
    });
  }

  function onEnter(e) {
    if (e.keyCode === 13) {
      useAddress();
    }
  }

  function useAddress() {
    setQuery({ address });
    setRedirectLocations(true);
  }

  if (redirectLocations) {
    return <Redirect to={{ pathname: "/locations", query }} />;
  }

  return (
    <div style={{ background: colors.light }}>
      <div className={sass.location}>
        <Container>
          <div className={sass.card}>
            <Row>
              <Column
                width="30%"
                className={sass.columnLeft}
                style={{
                  backgroundImage: `url(${storefront})`,
                }}
              >
                <h5 style={{ color: "#fff" }}>
                  Find one of our 9 conveniently located stores.
                </h5>
              </Column>
              <Column
                width="50%"
                className={sass.columnRight}
                style={{
                  backgroundImage: `url(${map})`,
                }}
              >
                <h3
                  style={{
                    color: "#fff",
                    marginBottom: "1em",
                    textShadow:
                      "2px 2px 6px rgba(0,0,0,0.15), 1px 1px 4px rgba(0,0,0,0.2)",
                  }}
                >
                  Find Your Location
                </h3>
                <div className={sass.search}>
                  <button
                    onClick={useLocation}
                    className={sass.button}
                    style={{
                      fontWeight: weights.bold,
                      cursor: "pointer",
                      width: "180px",
                    }}
                  >
                    {loading ? (
                      <FontAwesomeIcon
                        style={{ fontSize: "1.5em" }}
                        icon="spinner"
                        spin
                      />
                    ) : (
                      <Fragment>
                        Use my location
                        <FontAwesomeIcon
                          style={{ fontSize: "1.5em", marginLeft: ".4em" }}
                          icon="map-marker-alt"
                        />
                      </Fragment>
                    )}
                  </button>
                  <span
                    style={{
                      margin: ".5em 1em",
                      fontWeight: weights.bold,
                      fontSize: "1.25em",
                      color: "#fff",
                    }}
                  >
                    or
                  </span>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <input
                      value={address}
                      type="text"
                      placeholder="Enter zip code"
                      onKeyDown={onEnter}
                      onChange={({ target }) => setAddress(target.value)}
                      className={sass.input}
                      style={{
                        background: colors.kindaLight,
                        color: colors.dark,
                        width: "calc(180px - 3em)",
                      }}
                    />
                    <div style={{ position: "absolute" }}>
                      <button
                        onClick={useAddress}
                        style={{
                          padding: ".5em",
                          borderRadius: "50%",
                          marginRight: ".5em",
                        }}
                      >
                        <FontAwesomeIcon icon="search" />
                      </button>
                    </div>
                  </div>
                </div>
              </Column>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
}

function Reviews() {
  return (
    <Fragment>
      <Container style={{ paddingBottom: "2em" }}>
        <Heading title="What Our Customers Are Saying" />
      </Container>
      <div
        className={sass.review}
        style={{ backgroundImage: `url(${hangerMan})` }}
      >
        <Container>
          <div className={sass.reviewContainer}>
            <div>
              <img src={quoteOne} />
              <img src={quoteTwo} />
            </div>
            <div>
              <img src={quoteThree} />
            </div>
          </div>
        </Container>
      </div>
    </Fragment>
  );
}

export default function Home() {
  ReactGA.pageview(window.location.pathname);

  const [showModal, setShowModal] = useState(false);
  const [modalSource, setModalSource] = useState("");

  function openModal(source) {
    setShowModal(true);
    setModalSource(source);
  }

  return (
    <Fragment>
      <Hero minHeight={"400px"} image={heroHome}>
        <div className={sass.hero}>
          <h1
            style={{
              textShadow:
                "2px 2px 6px rgba(0,0,0,0.15), 1px 1px 4px rgba(0,0,0,0.2)",
            }}
          >
            Free Pickup and Delivery Service.
          </h1>
          <div className={sass.buttonContainer}>
            <Link to="/sign-up" className={theme.buttonLink}>
              Sign Up
            </Link>
            <button
              className={theme.invert}
              style={{ marginTop: ".5em" }}
              onClick={() => openModal("pickUp")}
            >
              Request Pickup
            </button>
          </div>
        </div>
      </Hero>

      <div className={sass.mobileButtonContainer}>
        <Link to="/sign-up" className={theme.buttonLink}>
          Sign Up
        </Link>
        <button
          className={theme.invert}
          onClick={() => openModal("pickUp")}
          style={{
            borderColor: colors.primary,
            borderWidth: "2px",
            borderStyle: "solid",
          }}
        >
          Request Pickup
        </button>
      </div>

      <Tagline />
      <Location />
      <App />
      <Reviews />
      <Faq />

      <div className={sass.getStarted}>
        <div
          className={sass.background}
          style={{ backgroundImage: `url(${truck}), url(${truck})` }}
        />
        <div style={{ zIndex: "2" }}>
          <h4>Subscribe to our email list today!</h4>
          <button
            style={{ margin: "0 auto" }}
            onClick={() => openModal("subscribe")}
          >
            Keep In Touch
          </button>
        </div>
      </div>

      <Modal show={showModal} exit={setShowModal}>
        {modalSource === "subscribe" ? (
          <SubscribeForm />
        ) : (
          <SignUpForm source={modalSource} />
        )}
      </Modal>
    </Fragment>
  );
}
