import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import FormField from '../form-field'
import { colors } from 'theme'

export default function SubscribeForm() {
  const { register, handleSubmit, errors } = useForm()
  const [feedback, setFeedback] = useState('')
  const [loading, setLoading] = useState(false)

  async function submit(values) {
    setLoading(true)
    const data = new FormData();
    for (const value in values) {
      data.append(value, values[value]);
    }
    axios.post("https://nationalcleanersassociation.emailcampaigns.net/CSB/Public/ProcessHostedForm.aspx", data)
    .then(() => {
      setFeedback(`We’ve sent a confirmation email to ${values['7298692']}. If you haven’t received it, make sure you check your junk mail folder, it might be hiding there.`)
    }).catch(() => {
      console.log(error)
      setFeedback('There was an error subscribing to the email list.')
    }).finally(() => setLoading(false))
  }

  return (
    <div style={{ padding: '3em' }} >
      <div style={{ textAlign: 'center' }}>
        <h3>Keep In Touch</h3>
        <h6>Subscribe to our email list</h6>
      </div>
      <form onSubmit={handleSubmit(submit)}>
        <FormField name="7298692" maxLength="4000" id="7298692" contactattributeid="7298692"
          autoComplete="off" error={errors['7298692']}
          register={register({
            required: 'Address is required'
          })}>
            Email Address
        </FormField>
        <FormField name="7298682" maxLength="4000" id="7298682" contactattributeid="7298682" register={register}>
            First Name
        </FormField>
        <FormField name="7298687" maxLength="4000" id="7298687" contactattributeid="7298687" register={register}>
            Last Name
        </FormField>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1em'
          }}>
            <button type="submit">
              { loading
                ? <FontAwesomeIcon style={{ fontSize: '1.5em' }} icon="spinner" spin />
                : 'Subscribe'
              }
            </button>
          </div>
        <input type="hidden" name="__VIEWSTATEGENERATOR" id="__VIEWSTATEGENERATOR" value="5523BD75" ref={register}/>
        <input type="hidden" name="FormInfo" id="FormInfo" value="5076d95f-29ce-45f3-b857-aa0cb69cd1f0" ref={register}/>
        <input type="hidden" name="AccId" id="AccId" value="dwy6" ref={register}/>
      </form>
      {feedback && <p style={{ textAlign: 'center', marginTop: '1em', color: feedback.error ? 'red' : colors.primary }}>
        {feedback}
      </p>}
    </div>
  )
}