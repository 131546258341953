export {default as logoWhite} from './logos/logo_white.png'

export {default as iconAlterations} from './icons/icon_alterations.png'
export {default as iconCouture} from './icons/icon_couture.png'
export {default as iconDelivery} from './icons/icon_delivery.png'
export {default as iconDeliveryAlt} from './icons/icon_delivery_alt.png'
export {default as iconDryCleaning} from './icons/icon_dry_cleaning.png'
export {default as iconEcoFresh} from './icons/icon_eco_fresh.png'
export {default as iconHousehold} from './icons/icon_household.png'
export {default as iconRestoration} from './icons/icon_restoration.png'
export {default as iconReturned} from './icons/icon_returned.png'
export {default as iconSameDay} from './icons/icon_same_day.png'
export {default as iconSchedule} from './icons/icon_schedule.png'
export {default as iconWashFold} from './icons/icon_wash_fold.png'
export {default as iconWeddingGown} from './icons/icon_wedding_gown.png'

export {default as app} from './app.png'
export {default as appStore} from './app_store.png'
export {default as googlePlay} from './google_play.png'
export {default as hangerMan} from './hanger_man.png'
export {default as heroContact} from './hero_contact.png'
export {default as heroHome} from './hero_home.png'
export {default as map} from './map.png'
export {default as storefront} from './storefront.png'
export {default as truck} from './truck.jpg'
export {default as quoteOne} from './quote_one.png'
export {default as quoteTwo} from './quote_two.png'
export {default as quoteThree} from './quote_three.png'
