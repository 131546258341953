
import React from 'react'

import { colors, weights } from 'theme'

export default function Heading({ title, subtitle }) {
  return (
    <div style={{
      alignItems: 'center',
      borderBottom: '1px solid #D8D8D8',
      maxWidth: '1100px',
      margin: '4em auto',
      padding: '1em 0',
      textAlign: 'center'
    }}>
      <h2 style={{
        color: colors.primary,
        fontWeight: weights.bold,
        marginBottom: '.1em'
      }}>
        {title}
      </h2>
      {subtitle && <p style={{ fontSize: '.9em' }}>{subtitle}</p>}
    </div>
  )
}
