const data = [
  {
    key: "Trade Center Way",
    address: "1926 Trade Center Way, Naples, FL 34109",
    phone: "(239) 596-3100",
    longitude: -81.7786228,
    latitude: 26.2246858,
    distance: 0,
    hours: {
      weekday: "8am - 5pm",
      saturday: "Closed",
      sunday: "Closed",
    },
  },
  {
    key: "The Strand Center",
    address: "5620 Strand Blvd, Naples, FL 34110",
    phone: "(239) 596-3026",
    longitude: -81.74639049999999,
    latitude: 26.2754831,
    distance: 0,
    hours: {
      weekday: "7am - 6pm",
      saturday: "9am - 2pm",
      sunday: "Closed",
    },
  },
  {
    key: "Naples Walk Center",
    address: "2430 Vanderbilt Beach Rd, Naples, FL 34109",
    phone: "(239) 596-8188",
    longitude: -81.7473479,
    latitude: 26.2437432,
    distance: 0,
    hours: {
      weekday: "7am - 6pm",
      saturday: "9am - 2pm",
      sunday: "Closed",
    },
  },
  {
    key: "Vineyards Crossroads",
    address: "6021 Pine Ridge Rd, Naples, FL 34119",
    phone: "(239) 353-6778",
    longitude: -81.72974789999999,
    latitude: 26.2130746,
    distance: 0,
    hours: {
      weekday: "7am - 6pm",
      saturday: "9am - 2pm",
      sunday: "Closed",
    },
  },
  {
    key: "Bonita Grande Crossings",
    address: "12950 Trade Way Four, Bonita Springs, FL 34135",
    phone: "(239) 495-0508",
    longitude: -81.73893869999999,
    latitude: 26.3295554,
    distance: 0,
    hours: {
      weekday: "7am - 6pm",
      saturday: "9am - 2pm",
      sunday: "Closed",
    },
  },
  {
    key: "Pebblebrooke Shoppes",
    address: "15275 Collier Blvd, Naples, FL 34119",
    phone: "(239) 352-5477",
    longitude: -81.6911131,
    latitude: 26.2719995,
    distance: 0,
    hours: {
      weekday: "7am - 6:30pm",
      saturday: "9am - 2pm",
      sunday: "Closed",
    },
  },
  {
    key: "Grande Oak Shoppes",
    address: "20301 Grande Oak Shoppes Dr, Estero, FL 33928",
    phone: "(239) 992-0028",
    longitude: -81.7698223,
    latitude: 26.4389336,
    distance: 0,
    hours: {
      weekday: "7am - 6pm",
      saturday: "9am - 2pm",
      sunday: "Closed",
    },
  },
  {
    key: "Pelican Bay Marketplace",
    address: "8855 Tamiami Trail N, Naples, FL 34108",
    phone: "(239) 594-5803",
    longitude: -81.802932,
    latitude: 26.2497192,
    distance: 0,
    hours: {
      weekday: "7am - 6pm",
      saturday: "9am - 2pm",
      sunday: "Closed",
    },
  },
  {
    key: "Sky Walk Center",
    address: "13111 Paul J. Doherty Parkway #120, Fort Myers, FL 33913",
    phone: "(239) 288-6491",
    longitude: -81.74787309999999,
    latitude: 26.5556073,
    distance: 0,
    hours: {
      weekday: "7am - 6:30pm",
      saturday: "9am - 2pm",
      sunday: "Closed",
    },
  },
];

module.exports = { data };
