import axios from "axios";

const endpoint =
  "https://a25ihdpt07.execute-api.us-east-1.amazonaws.com/prod/mailer/send";
const headers = {
  "Content-Type": "application/json",
  "X-Api-Key": "zlQvaaUgjK5htLMjxIKQ249r7hesK5cH5yESDrIg",
};

async function submitForm(body) {
  const response = await axios
    .post(endpoint, body, { headers })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      throw error;
    });

  return response;
}

export default submitForm;
