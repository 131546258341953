
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faTwitterSquare, faInstagramSquare } from '@fortawesome/free-brands-svg-icons'

import { colors } from 'theme'
import { iconExternalLink} from 'images'
import sass from './footer.sass'
import { Link } from 'react-router-dom'
import Container from '../container'

const social = [
  {
    key: 'facebook',
    icon: faFacebookSquare,
    link: 'https://www.facebook.com/myfashionfresh'
  },
  {
    key: 'twitter',
    icon: faTwitterSquare,
    link: 'https://twitter.com/myfashionfresh'
  },
  {
    key: 'instagram',
    icon: faInstagramSquare,
    link: 'https://www.instagram.com/myfashionfresh'
  }
]

export default function Footer() {
  return (
    <footer style={{
      color: colors.kindaLight,
    }}>
      <div style={{background: colors.primary }}>
        <Container className={sass.footerColumns}>
          <section>
            <h4>Quick Links</h4>
            <Link className={sass.link} to="/locations">Find A Location</Link>
            <Link className={sass.link} to="/contact">Contact Us</Link>
            <Link className={sass.link} to="/credit-card">Update Credit Card</Link>
            <a className={sass.link} href="/privacy-policy">Privacy Policy</a>
            <a className={sass.link} href="/terms-of-service">Terms of Service</a>
            <a className={sass.link} href="/google-play-policy">Google Play Policy</a>
          </section>
          <section className={sass.rightColumn}>
            <h4 className={sass.follow}>Follow us on</h4>
            {social.map(platform => 
              <a key={platform.key} href={platform.link} target="_blank" className={sass.icon}>
                <FontAwesomeIcon icon={platform.icon} />
              </a>
            )}
          </section>
        </Container>
      </div>
      <div style={{ backgroundColor: colors.dark, paddingTop: '1em', paddingBottom: '.5em' }}>
        <Container style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p style={{ marginRight: '1em' }}>
            Copyright &copy; {new Date().getFullYear()} Fashion Fresh All Rights Reserved
          </p>
          <p>Powered by Kudo Creative</p>
        </Container>
      </div>
    </footer>
  )
}
