import AboutUs from "./about-us";
import Contact from "./contact";
import Home from "./home";
import HowItWorks from "./how-it-works";
import Locations from "./locations";
import Services from "./services";
import Policy from "./policy";
import Jobs from "./jobs";
import Job from "./job";
import Comca from "./comca";
import GooglePlayPolicy from "./google-play-policy";

module.exports = {
  AboutUs,
  Comca,
  Contact,
  GooglePlayPolicy,
  Home,
  HowItWorks,
  Locations,
  Policy,
  Services,
  Jobs,
  Job,
};
