import React, { useState, Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { logoWhite } from "images";
import { colors, weights } from "theme";
import { useMedia, useScreenSizes } from "helpers";
import Container from "../container";

import sass from "./header.sass";

function DesktopHeader() {
  return (
    <nav
      style={{
        background: colors.primary,
        height: "100px",
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 3,
        boxShadow: "rgba(0, 0, 0, 0.4) 1px 1px 6px",
      }}
    >
      <Container
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          flexWrap: "nowrap",
          justifyContent: "space-between",
          maxWidth: "1300px",
        }}
      >
        <img className={sass.logo} src={logoWhite} />
        <div style={{ display: "flex", height: "100%" }}>
          {links.map((l) => (
            <NavLink
              exact
              key={l.key}
              to={l.route}
              activeStyle={{ fontWeight: 500 }}
              className={sass.link}
            >
              {l.key}
            </NavLink>
          ))}
          <a
            href="tel:2395963100"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              className={sass.phone}
              style={{
                borderColor: colors.kindaLight,
                color: colors.kindaLight,
                fontWeight: weights.regular,
              }}
            >
              <FontAwesomeIcon
                icon="phone-alt"
                style={{
                  color: colors.kindaLight,
                  marginRight: ".5em",
                }}
              />
              239.596.3100
            </div>
          </a>
        </div>
      </Container>
    </nav>
  );
}

function MobileHeader() {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <div
        style={{
          background: colors.primary,
          display: "flex",
          height: "30px",
          padding: "1em",
          position: "fixed",
          width: "calc(100% - 2em)",
          zIndex: 4,
          justifyContent: "space-between",
          boxShadow: "rgba(0, 0, 0, 0.4) 1px 1px 6px",
        }}
      >
        <Link to="/">
          <img src={logoWhite} style={{ width: "100px" }} />
        </Link>
        <div style={{ alignItems: "center", display: "flex" }}>
          <a
            href="tel:2395963100"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <FontAwesomeIcon
              icon="phone-alt"
              style={{
                color: colors.kindaLight,
                fontSize: "1.5em",
              }}
            />
          </a>
          <FontAwesomeIcon
            onClick={() => setOpen(!open)}
            icon={open ? "times" : "bars"}
            style={{
              color: colors.kindaLight,
              fontSize: "1.5em",
              marginLeft: "1.5em",
              width: "20px",
            }}
          />
        </div>
      </div>
      <div
        style={{
          height: open ? "100%" : 0,
          width: "100%",
          position: "fixed",
          backgroundColor: "#000",
          transition: "opacity .4s linear",
          opacity: open ? 0.6 : 0,
          zIndex: 3,
        }}
      />
      <div
        style={{
          background: colors.primary,
          fontSize: "16px",
          top: "0",
          position: "fixed",
          transition: "0.5s",
          transform: open ? "translateY(62px)" : "translateY(-270px)",
          width: "calc(100% - 2em)",
          zIndex: 3,
          display: "flex",
          justifyContent: "flex-end",
          padding: "1em",
        }}
      >
        <div>
          {links.map((l) => (
            <NavLink
              exact
              activeStyle={{ fontWeight: 500 }}
              key={l.key}
              to={l.route}
              onClick={() => setOpen(false)}
              style={{
                color: colors.kindaLight,
                display: "block",
                padding: ".5em 0",
                textAlign: "right",
              }}
            >
              {l.key}
            </NavLink>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default function Header() {
  const isSmallDevice = useMedia(useScreenSizes.lessThan("md"));

  if (isSmallDevice) return <MobileHeader />;
  else if (!isSmallDevice) return <DesktopHeader />;
  else return null;
}

const links = [
  {
    key: "Home",
    route: "/",
  },
  {
    key: "Services",
    route: "/services",
  },
  {
    key: "How It Works",
    route: "/how-it-works",
  },
  {
    key: "Locations",
    route: "/locations",
  },
  {
    key: "About Us",
    route: "/about-us",
  },
  {
    key: "Contact",
    route: "/contact",
  },
  {
    key: "Jobs",
    route: "/jobs",
  },
];
