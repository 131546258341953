import React from "react";
import ReactGA from "react-ga";
import { jobs } from "./data";
import { Container } from "components";
import { Link } from "react-router-dom";
import { colors, theme } from "theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

export default function Jobs() {
  ReactGA.pageview(window.location.pathname);

  return (
    <Container style={{ paddingTop: "3em", paddingBottom: "4em" }}>
      <h3 style={{ color: colors.primary }}>Job Openings</h3>
      {[...jobs].map(([key, value]) => (
        <div style={{ padding: "1.5em 0" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexFlow: "wrap",
            }}
          >
            <div>
              <h5>
                <Link to={`/job/${key}`}>{value.title} </Link>
              </h5>
              <p>
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  style={{ color: colors.primary, marginRight: "3px" }}
                />{" "}
                {value.location}
              </p>
            </div>

            <Link
              className={theme.buttonLink}
              style={{ display: "inline-block", height: "100%" }}
              to={`/job/${key}`}
            >
              Apply now
            </Link>
          </div>
          <div
            style={{
              padding: "0.5em 0",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {value.description}
          </div>
        </div>
      ))}
    </Container>
  );
}
