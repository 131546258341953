import React from "react";
import ReactGA from "react-ga";

import { Container } from "components";

import sass from "./comca.sass";

export default function Comca({ src, height }) {
  ReactGA.pageview(window.location.pathname);

  return (
    <iframe
      style={{ height }}
      className={sass.comca}
      scrolling="no"
      frameBorder="0"
      src={src}
    ></iframe>
  );
}
