import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga";

import { Container, SignUpForm } from "components";
import { colors, weights } from "theme";
import {
  iconAlterations,
  iconCouture,
  iconDelivery,
  iconDryCleaning,
  iconEcoFresh,
  iconHousehold,
  iconRestoration,
  iconSameDay,
  iconWashFold,
  iconWeddingGown,
} from "images";
import sass from "./services.sass";

const services = [
  { icon: iconDryCleaning, name: "Dry Cleaning" },
  { icon: iconDelivery, name: "Free Pickup & Delivery" },
  { icon: iconSameDay, name: "Same Day Dry Cleaning" },
  { icon: iconWashFold, name: "Wash & Fold Laundry" },
  { icon: iconCouture, name: "Couture Fabric" },
  { icon: iconAlterations, name: "Alterations & Repairs" },
  { icon: iconHousehold, name: "Household Item & Bedding" },
  { icon: iconWeddingGown, name: "Wedding Gowns Service" },
  { icon: iconRestoration, name: "Garment Restoration" },
  { icon: iconEcoFresh, name: "ECO Fresh" },
];

export default function Services() {
  ReactGA.pageview(window.location.pathname);

  return (
    <Fragment>
      <Container>
        <div className={sass.headerPadding}>
          <h3 style={{ color: colors.primary }}>Services</h3>
          <p>With Fashion Fresh, it's right, it's ready, it's guaranteed.</p>
        </div>
        <div className={sass.grid}>
          {services.map((service) => (
            <div key={service.name} className={sass.gridItem}>
              <img src={service.icon} />
              <p style={{ color: colors.primary, fontWeight: weights.regular }}>
                {service.name}
              </p>
            </div>
          ))}
        </div>
      </Container>
      <section
        style={{
          background: colors.primary,
          textAlign: "center",
          padding: "2em",
        }}
      >
        <a
          href="https://fashionfresh-assets.s3.amazonaws.com/Fashion_Fresh_Price_List_2023.pdf"
          target="_blank"
        >
          <h5
            style={{
              color: "#fff",
              fontWeight: weights.bold,
              margin: 0,
            }}
          >
            View Full Pricing List{" "}
            <FontAwesomeIcon style={{ color: "#fff" }} icon={faChevronRight} />
          </h5>
        </a>
      </section>
      <SignUpForm source={"signUp"} />
    </Fragment>
  );
}
