
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

import { colors, weights } from 'theme'
import Row from '../row'
import Column from '../column'
import Container from '../container'
import sass from './faq.sass'

function Question(props) {
  const [open, setOpen] = useState(false)
  
  return (
    <Column onClick={() => setOpen(!open)} width="33%">
      <div className={sass.question} style={{ background: colors.dark }}>
        <div className={sass.container}>
          <p style={{ marginBottom: 0, marginRight: '1em', fontWeight: weights.regular }}>{props.question}</p>
          <FontAwesomeIcon style={{ fontSize: '24px' }} icon={open ? faCaretUp : faCaretDown} />
        </div>
        <div style={{
          transition: 'max-height .5s ease-in-out',
          overflow: 'hidden',
          height: 'auto',
          display: open ? 'block' : 'none',
          wordBreak: 'break-word',
          paddingTop: '1em'
        }}>
          {props.answer}
        </div>
      </div>
    </Column>
  )
}

export default function Faq(props) {
  return (
    <section className={sass.faq} style={{ background: colors.primary }}>
      <h5 style={{ color: '#fff', textAlign: 'center' }}>
        Frequently Asked Questions
      </h5>
      <Container>
        <Row>
          {questions.map((question) => 
            <Question key={question.key} question={question.key} answer={question.answer} />
          )}
        </Row>
      </Container>
    </section>
  )
}

const questions = [
  {
    key: "Do I have to be home to have pickup and delivery service?",
    answer: "No. When you first open your account, you establish a safe dry pickup and delivery"
      + " location. In the morning, you put your purple bag in the predesignated location and your"
      + " route concierge delivers your fresh clean garments to the predetermined location."
  },
  {
    key: "How do I sign up for the free pick up and delivery service?",
    answer: "There are several ways to signup for Pickup and delivery service."
    + " Downloading the Fashion Fresh App is the best and easiest. Once"
    + " you complete the short new customer form through the app, our route manager"
    + " will contact you to answer any questions and get you setup by scheduling your"
    + " first pickup."
  },
  {
    key: "I do not do that much dry cleaning so can I still use the pickup and delivery service.",
    answer: "Absolutely. It doesn’t matter if you do one item a year or several items weekly. You"
      + " simply download our app and request a pickup the day before you need your items picked"
      + " up. You can have service on demand or weekly regular service."
  },
  {
    key: "What do I do if I’m on the pickup and delivery service and I go on vacation?",
    answer: "No problem. Just notify us through the Fashion Fresh App, and we will put your account"
      + " on a vacation hold until the day you are going to return."
  }
]