
import React from 'react'

import sass from './row.sass'

export default function Row({ children, id, reverse, style }) {
  let classes = [sass.row]
  if (reverse)
    classes.push(sass.reverse)

  classes = classes.join(' ')

  return (
    <div id={id} className={classes} style={{ ...style }}>
      {children}
    </div>
  )
}
