
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { colors } from 'theme'
import sass from './modal.sass'

export default function Modal({ children, exit, show }) {
  if (show) return (
    <div className={sass.modal}>
      <div className={sass.container}>
        <a className={sass.exit} onClick={() => exit(false)}>
          <FontAwesomeIcon style={{ color: colors.dark, fontSize: '1.5em' }} icon="times" />
        </a>
        {children}
      </div>
    </div>
  )
  else return null
}
