import React from 'react'
import ReactGA from 'react-ga';

import { Column, Container, Row, SignUpForm } from 'components'
import { iconDeliveryAlt, iconReturned, iconSchedule } from 'images'
import { colors, weights } from 'theme'

const data = [
  {
    key: 'schedule',
    image: iconSchedule,
    text: "Call by 9am and receive same day pickup."
  },
  {
    key: 'deliver',
    image: iconDeliveryAlt,
    text: "A Fashion Fresh representative will pick up your items to have them " +
     "cleaned according to the service you've selected."
  },
  {
    key: 'return',
    image: iconReturned,
    text: "Your items are returned cleaned and on hangers or neatly folded."
  }
]

export default function HowItWorks() {
  ReactGA.pageview(window.location.pathname);

  return (
    <React.Fragment>
      <Container>
        <div style={{ paddingBottom: '3em', paddingTop: '3em' }}>
          <h3>How It Works</h3>
          <h5>Welcome to Fashion Fresh</h5>
          <p style={{ maxWidth: '700px' }}>
            Fashion Fresh is a one stop solution for all your dry cleaning needs.
            We offer on-demand laundry wash and fold services for residential customers,
            wet and dry cleaning as well as laundry services for small and medium sized
            businesses.
          </p>
        </div>
        <Row style={{ paddingBottom: '3em' }}>
          {data.map((x, i) =>
            <Column key={x.key} width="25%" style={{ textAlign: 'center' }}>
              <div style={{
                alignItems: 'center',
                borderColor: colors.primary,
                borderRadius: '50%',
                borderStyle: 'solid',
                borderWidth: '3px',
                color: colors.primary,
                display: 'flex',
                fontSize: '1.25em',
                fontWeight: weights.bold,
                height: '40px',
                justifyContent: 'center',
                margin: '2em auto',
                width: '40px',
              }}>
                {i+1}
              </div>
              <img style={{ maxHeight: '75px', width: 'auto' }} src={x.image} />
              <p>{x.text}</p>
            </Column>
          )}
        </Row>
        <Row>
          <div style={{
            width: '100%',
            fontStyle: 'italic',
            textAlign: 'center',
            paddingBottom: '1em',
            paddingLeft: '2em',
            paddingRight: '2em'
          }}>
            <p style={{ marginBottom: 0 }}>Current route customers should text "Yes" to 833-201-1704 to schedule a pickup.</p>
            <p>New route customers text "New" to 833-201-1704 to sign up for FREE pickup and delivery.</p>
          </div>
        </Row>
      </Container>
      <SignUpForm source={'signUp'} />
    </React.Fragment>
  )
}

