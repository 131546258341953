export const jobs = new Map([
  [
    "team-member",
    {
      title: "Team Member",
      pay: "$14.00 per hour up to $17.00 on a full-time basis",
      location: "Naples, FL",
      description:
        "Fashion Fresh is looking for full-time Team Members to join our close-knit team.  We are a 3rd generation, family owned and operated company with a great reputation in our local market.  Fashion Fresh is an industry leader in dry-cleaning & laundry services.  Fashion Fresh is a customer-focused group that provides unique advantages to our customers such as same day service, 6 days a week and our Customer Rewards Program.",
      benefits: [
        "Compensation starting at $14.00 per hour up to $17.00 on a full-time basis",
        "Paid Time Off",
        "Paid Holidays",
        "Health, Vision, and Dental Insurance",
        "401k with matching employer contributions",
        "Company provided uniforms",
        "Technical training",
        "50% off Employee cleaning discount",
      ],
      responsibilties: [
        "Greet all incoming customers with enthusiasm and courtesy",
        "Duties include the use of Point of Sale computer system, inspecting and bagging orders, racking and hanging clothes, marking-in orders and maintaining the cleanliness of the store",
        "Operate steam pressing machines to remove wrinkles from garments",
        "Working with your team to deliver same day dry-cleaning & laundry services for our customers",
        "Additional duties as assigned",
      ],
      qualifications: [
        "Experience working in a fast-paced, customer service focused environment",
        "Must be able to work a flexible schedule including Saturdays",
        "Excellent attention to detail with the ability to handle client's garment with care",
        "Ability to get to different locations, locally, via transit or personal transportation as scheduled",
        "Experience handling money and financial transactions",
        "Previous industry experience preferred, but not required and must be 18 or older",
        "Bilingual in Spanish is a plus, but is not required",
      ],
    },
  ],
  [
    "production-presser",
    {
      title: "Production Presser",
      pay: "$14.00 per hour up to $17.00 on a full-time basis",
      location: "Naples, FL",
      description:
        "Fashion Fresh is looking for full-time Pressers to join our close-knit team. We are a 3rd generation, family-owned and operated company with a great reputation in our local market. Fashion Fresh is an industry leader in dry-cleaning & laundry services. Fashion Fresh is a customer-focused group that provides unique advantages to our customers such as same-day service, 6 days a week, and our Customer Rewards Program.",
      benefits: [
        "Compensation starting at $14.00 per hour up to $17.00 on a full-time basis",
        "Paid Time Off",
        "Paid Holidays",
        "Health, Vision, and Dental Insurance",
        "401k with matching employer contributions",
        "Company provided uniforms",
        "Technical training",
        "50% off Employee cleaning discount",
      ],
      responsibilties: [],
      qualifications: [
        "Meet all Pressing standards of quality and efficiency",
        "Must be able to learn minor mending of customer garments",
        "Work with team to deliver same day dry cleaning & laundry services, where needed",
        "Additional duties as assigned",
        "Experience working in a fast-paced environment",
        "Must be available to work flexible hours, including Saturday",
        "Excellent attention to detail with the ability to handle client's garment with care",
        "Ability to get to different locations, locally via transit or personal transportation as scheduled",
        "Bilingual in Spanish is a plus but is not required",
        "Good hand eye coordination",
        "Ability to physically manipulate and learn skills necessary for operation pressing equipment",
      ],
    },
  ],
  [
    "route-driver",
    {
      title: "Route Driver",
      pay: "$600.00 - $650.00 per week",
      location: "Naples, FL",
      description:
        "Looking for energetic, non-smoking candidate for industry leading Naples Master Dry Cleaner in the fast growing Bonita and Estero area! Great Income Potential with ability to grow your pay!!  Starting pay structure provides base pay plus commission and new customer bonus.  This opportunity is perfect for career minded individual with strong communication skills and is task oriented.  Because this position involves driving a company vehicle, please include a copy of current Florida driver's license along with resume to email listed.",
      benefits: [],
      responsibilties: [
        "Duties include retention calls by phone and in-person.",
      ],
      qualifications: [
        "Candidate must be Income and Career Motivated with a Strong Desire to Succeed.",
        "Sales skills with face to face experience preferred, but will train the right person.",
        "Must have ability to work flexible hours and self-motivated to grow.",
        "Customer service experience essential.",
        "Must have clean driving record and license.",
      ],
    },
  ],
]);
