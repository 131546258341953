import React from 'react'
import ReactGA from 'react-ga';

import { Column, Container, Row } from 'components'

export default function AboutUs() {
  ReactGA.pageview(window.location.pathname);

  return (
    <Container style={{ paddingBottom: '4em' }}>
      <div style={{ paddingTop: '3em' }}>
        <h3>About Us</h3>
        <h5>The Best Dry Cleaners in Southwest Florida</h5>
        <p>Clean Clothes. Happy People. Since 1987. Serving Naples, Ft. Myers and Bonita Springs</p>
        <div style={{
          maxWidth: '1100px',
          background: '#d6d6d6',
          margin: '3em auto',
          height: '1px'
        }}/>
      </div>
      <Row reverse>
        <Column width="40%">
          <p style={{ marginBottom: '1.5em' }}>
            High quality, reasonable prices and fantastic service is something all of us want.
            But more than that, it is nice to have someone who knows you and how you like your
            garments cared for.
          </p>
          <p style={{ marginBottom: '1.5em' }}>
            Enter Fashion Fresh Dry Cleaning and Alterations, your neighborhood dry cleaners.
            Since 1987 we've taken the promise of personal service and delivered it to you, literally.
            With each pick-up and delivery, you can rest assured that your clothes have received loving
            care to make them look their best - just the way you asked.
          </p>
          <p style={{ marginBottom: '1.5em' }}>
            Our large selection of services will meet any need you have and are one reason why we are
            largely recognized in the area as the premier dry cleaner. Pick up laundry services, fluff
            and fold services, alterations, same day dry cleaning, garment repair, and dry cleaning at
            home are some of our services. If you have any special need, feel free to give us a call, we
            are here to serve you.
          </p>
          <p style={{ marginBottom: '1.5em' }}>
            While state-of-the-art equipment and advanced garment care techniques may deliver fresh,
            clean garments, only a personal touch truly delivers happy customers. And we want to be your
            dry cleaners for life.
          </p>
          <p style={{ marginBottom: '1.5em' }}>
            We are proud of what we do, and it shows! From counter representatives and drivers to pressers
            and tailors, we treat each garment as if it were our own. We will keep your clothing looking its
            best, so that you can too! Give us a call and see why we are the best dry cleaner in Ft. Myers,
            Naples and Bonita Springs.
          </p>
        </Column>
        <Column width="40%">
        <iframe width="100%" height="275" src="https://www.youtube.com/embed/7ODhceT91ew"
            frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
        </iframe>
        </Column>
      </Row>
      <div style={{ paddingTop: '3em' }}>
        <h3>Management Concept Group</h3>
        <p>
          We are proud to be a member of the Management Concept Group, which is an invitation only group
          of elite dry cleaners from around the country. Here is a list of some of our other members:</p>
        <div style={{
          maxWidth: '1100px',
          background: '#d6d6d6',
          margin: '1em auto',
          height: '1px'
        }}/>
        <ul>
          <li><a href="https://zengelercleaners.com" target="_blank">zengelercleaners.com</a></li>
          <li><a href="https://bestcleaners.com" target="_blank">bestcleaners.com</a></li>
          <li><a href="https://dependablecleaners.com" target="blank">dependablecleaners.com</a></li>
          <li><a href="https://dosummers.com" target="_blank">dosummers.com</a></li>
          <li><a href="https://klinkecleaners.com" target="_blank">klinkecleaners.com</a></li>
          <li><a href="https://konacleaners.com" target="_blank">konacleaners.com</a></li>
          <li><a href="https://prestigecleaners.com" target="_blank">prestigecleaners.com</a></li>
          <li><a href="https://growth-advantage.com" target="_blank">growth-advantage.com</a></li>
          <li><a href="https://crestadvanceddrycleaners.com" target="_blank">crestadvanceddrycleaners.com</a></li>
          <li><a href="https://todaycleaners.com" target="_blank">todaycleaners.com</a></li>
          <li><a href="https://zikercleaners.com" target="_blank">zikercleaners.com</a></li>
          <li><a href="https://myfashionfresh.com" target="_blank">myfashionfresh.com</a></li>
        </ul>
      </div>
    </Container>
  )
}

