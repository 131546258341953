import React from 'react'

import { Container } from 'components'


export default function GooglePlayPolicy() {

  const link = (href, label) => {
    return <a href={href} target="_blank">{label}</a>
  }

  return (
    <Container>
        <div style={{ paddingTop: "3em", paddingBottom: "3em"}}>
            <h3>Personal and Sensitive User Data</h3>
            <p>Personal and sensitive user data includes, but isn't limited to, personally identifiable information, financial and payment information, authentication information, phonebook, contacts, {link("https://developer.android.com/training/location","device location")}, SMS and call-related data, {link("https://support.google.com/googleplay/android-developer/answer/10787469#type-health-info&zippy=data-types", "health data")}, {link("https://support.google.com/googleplay/android-developer/answer/9888170#ahp", "Health Connect")} data, inventory of other apps on the device, microphone, camera, and other sensitive device or usage data. If your app handles personal and sensitive user data, then you must:</p>
    
            <ul>
                <li>Limit the access, collection, use and sharing of personal and sensitive user data acquired through the app to app and service functionality and policy-conforming purposes reasonably expected by the user:</li>
                <li>Apps that extend usage of personal and sensitive user data for serving advertising must comply with Google Play’s {link("https://support.google.com/googleplay/android-developer/answer/9857753#location-data", "Ads policy")}.</li>
                <li>You may also transfer data as necessary to {link("https://support.google.com/googleplay/android-developer/answer/10787469#service-provider&zippy=%2Csharing%2Cdata-sharing", "service providers")} or for legal reasons such as to comply with a valid governmental request, applicable law, or as part of a merger or acquisition with legally adequate notice to users.</li>
                <li>Handle all personal and sensitive user data securely, including transmitting it using modern cryptography (for example, over HTTPS).</li>
                <li>Use a runtime permissions request whenever available, prior to accessing data gated by {link("https://developer.android.com/guide/topics/permissions/overview", "Android permissions")}.</li>
                <li>Not sell personal and sensitive user data.</li>
                <ul>
                    <li>"Sale" means the exchange or transfer of personal and sensitive user data to a {link("https://support.google.com/googleplay/android-developer/answer/10787469#first-and-third&zippy=%2Csharing%2Cdata-sharing", "third party")} for monetary consideration.</li>
                    <ul>
                        <li>User-initiated transfer of personal and sensitive user data (for example, when the user is using a feature of the app to transfer a file to a third party, or when the user chooses to use a dedicated purpose research study app), is not regarded as sale.</li>
                    </ul>
                </ul>
            </ul>

            <h3>Prominent Disclosure & Consent Requirement</h3>
            <p>In cases where your app’s access, collection, use, or sharing of personal and sensitive user data may not be within the reasonable expectation of the user of the product or feature in question (for example, if data collection occurs in the background when the user is not engaging with your app), you must meet the following requirements:</p>
    
            <strong>Prominent disclosure: You must provide an in-app disclosure of your data access, collection, use, and sharing. The in-app disclosure:</strong>
    
            <ul>
                <li>Must be within the app itself, not only in the app description or on a website;</li>
                <li>Must be displayed in the normal usage of the app and not require the user to navigate into a menu or settings;</li>
                <li>Must describe the data being accessed or collected;</li>
                <li>Must explain how the data will be used and/or shared;</li>
                <li>Cannot only be placed in a privacy policy or terms of service; and</li>
                <li>Cannot be included with other disclosures unrelated to personal and sensitive user data collection.</li>
            </ul>

            <strong>Consent and runtime permissions: Requests for in-app user consent and runtime permission requests must be immediately preceded by an in-app disclosure that meets the requirement of this policy. The app's request for consent:</strong>
            <ul>
                <li>Must present the consent dialog clearly and unambiguously;</li>
                <li>Must require affirmative user action (for example, tap to accept, tick a check-box);</li>
                <li>Must not interpret navigation away from the disclosure (including tapping away or pressing the back or home button) as consent;</li>
                <li>Must not use auto-dismissing or expiring messages as a means of obtaining user consent; and</li>
                <li>Must be granted by the user before your app can begin to collect or access the personal and sensitive user data.</li>
            </ul>

            <p>Apps that rely on other legal bases to process personal and sensitive user data without consent, such as a legitimate interest under the EU GDPR, must comply with all applicable legal requirements and provide appropriate disclosures to the users, including in-app disclosures as required under this policy.</p>
            <p>To meet policy requirements, it’s recommended that you reference the following example format for Prominent Disclosure when it’s required:</p>
    
            <ul>
                <li>“[This app] collects/transmits/syncs/stores [type of data] to enable  ["feature"], [in what scenario]."</li>
                <li>Example: “Fitness Funds collects location data to enable fitness tracking even when the app is closed or not in use and is also used to support advertising.”</li>
                <li>Example: “Call buddy collects read and write call log data to enable contact organization even when the app is not in use.”</li>
            </ul>
        </div>
    </Container>
  )
}

